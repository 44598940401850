import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import * as PropTypes from 'prop-types';
import { debounce } from 'lodash';
import DocumentReviewGrid from './components/DocumentReviewGridComponent';
import styles from './styles.modules.less';
import LeftNavFilter from './components/LeftNavComponent/index';
import store from '../reviews-modal/store';
import ROUTE from './constants/route';
import { filterParams, USER } from './helper';

const USER_ID = USER.id;

const Reviews = (props) => {
  const { route = ROUTE.reviews } = props;
  const [leftNavParam, setLeftNavParam] = useState(
    route === ROUTE.inbox ? filterParams({ member_id: USER_ID }) : {}
  );
  const [filterFlyoutParam, setFilterFlyoutParam] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const getFilterCondition = (e) => {
    setFilterFlyoutParam(e.detail?.filterData);
  };
  useEffect(() => {
    document.addEventListener('filter-flyout-change', getFilterCondition);
    return () => {
      document.removeEventListener(
        'filter-flyout-change',
        getFilterCondition,
        false
      );
    };
  }, []);

  const handleChangeParams = (param) => {
    setLeftNavParam(param);
  };

  const handleDebounceSearch = debounce(({ target }) => {
    setSearchValue(target.value);
  }, 500);

  return (
    <Provider store={store}>
      <div className={styles['review-component']}>
        <div className={styles['review-component__reviewList']}>
          <LeftNavFilter
            defaultLeftNav={route === ROUTE.inbox ? 1 : 0}
            changeFilterParam={handleChangeParams}
            filterFlyoutParam={filterFlyoutParam}
          />
        </div>
        <div className={styles['review-component__reviewGrid']}>
          <div className="v3-flex v3-justify-between">
            <div className="v3-form-control__wrapper" style={{ width: 200 }}>
              <label
                className="v3-form-control__prefix"
                htmlFor="preview-quick-search"
              >
                <i className="fas fa-search" />
              </label>
              <input
                id="preview-quick-search"
                className="v3-form-control"
                placeholder="Quick Search"
                onChange={handleDebounceSearch}
              />
            </div>
            {props.children}
          </div>
          <DocumentReviewGrid
            leftNavParam={leftNavParam}
            filterFlyoutParam={filterFlyoutParam}
            searchValue={searchValue}
          />
        </div>
      </div>
    </Provider>
  );
};

Reviews.propTypes = {
  route: PropTypes.string,
  children: PropTypes.node,
};
export default Reviews;
