import { v2Endpoint } from '@pcs/pulse-components';
import PulseBryntumGrid from '@pcs/pulse-components/PulseBryntumGrid';
import '@pcs/pulse-components/PulseBryntumGrid/PulseBryntumGrid.css';
import clsx from 'clsx';
import utilities, {
  camelizeKeys,
  parseJsonApi,
  useInterval,
} from 'jsAssets/utilities';
import Echo from 'laravel-echo';
import isEqual from 'lodash/isEqual';
import * as PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import qs from 'qs';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLayoutEffect } from '@react-spring/shared';
import { filter } from 'lodash';
import PulseAssetDetail from '../../../asset-detail';
import { useReviewIdByDeepLinks } from '../../../asset-detail/helpers';
import {
  COLUMNS_CONFIGS_KEY_INSIDE_PROJECT,
  COLUMNS_CONFIGS_KEY_NON_PROJECT,
  COLUMNS_CONFIGS_KEY_TASK_FORM,
  FIELDS_TO_COLUMNS,
  REVIEW_GRID_JQ,
} from '../../constants/review-grid-constant';
import { CONFIG, GRID_ACTIONS, REQUEST, URLS, USER } from '../../helper';
import ReviewModalContext from '../ReviewModalContext';
import EmptyReview from './components/empty-review';
import {
  DateCreatedRenderer,
  DeadlineRenderer,
  ProgressRenderer,
  ProjectRenderer,
  ReviewRenderer,
  ReviewerRenderer,
  SourceRenderer,
  StatusRenderer,
  ViewBtnRenderer,
} from './review-grid-column-renderer';
import styles from './styles.modules.less';
import { STAGE_STATUS } from '../../constants/review-stages';

const REVIEW_URL = `${URLS.app}api/reviews`;

const CONTENT = REQUEST.content || '';

const RUN_WEBSOCKETS = CONFIG.ws.websocket_enabled;

if (RUN_WEBSOCKETS) {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: 'pusher',
    wsHost: CONFIG.ws.websocket_host,
    wsPort: CONFIG.ws.websocket_port,
    wssHost: CONFIG.ws.websocket_host,
    wssPort: CONFIG.ws.websocket_port,
    key: CONFIG.ws.websocket_key,
    cluster: 'mt1',
    forceTLS: CONFIG.ws.websocket_tls,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
  });
}

const UNAVAILABLE_REVIEW_STATUSES = ['failed', 'processing', 'uploading'];

const DEFAULT_AJAX_STORE_PARAMS = {
  jq: REVIEW_GRID_JQ,
};

const DEFAULT_COLUMNS_NON_PROJECT = [
  {
    field: 'source_img',
    text: '',
    width: 50,
    align: 'center',
    hideable: false,
    sortable: false,
    draggable: false,
    resizable: false,
    cellCls: styles['custom-col-image'],
    renderer: ({ record }) => {
      const { data } = record || {};
      return <SourceRenderer record={data} />;
    },
  },
  {
    field: 'source',
    text: 'REVIEW TITLE',
    width: 200,
    draggable: false,
    cellCls: styles['custom-col-review'],
    renderer: ({ record }) => {
      const { data } = record || {};
      return <ReviewRenderer record={data?.round_title} />;
    },
  },
  {
    field: 'round_number',
    text: 'ROUND',
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    align: 'center',
    hidden: true,
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'job_number',
    text: 'JOB NUMBER',
    cellCls: clsx(styles['custom-col-project'], 'job-number-col'),
    width: 150,
    draggable: false,
  },
  {
    field: 'job_title',
    text: 'PROJECT',
    cellCls: styles['custom-col-project'],
    width: 150,
    draggable: false,
    renderer: ({ value }) => {
      return <ProjectRenderer record={value} />;
    },
  },
  {
    field: 'name',
    text: 'LATEST STAGE',
    cellCls: styles['custom-col-wrapper'],
    width: 150,
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'brand_title',
    text: 'BRAND',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    renderer: ({ value }) => {
      return <span className={styles['review-col-ellipsis']}>{value}</span>;
    },
  },
  {
    field: 'round_type_order_by',
    text: 'TYPE',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    renderer: ({ value }) => {
      return (
        <span
          className={clsx(
            styles['review-col-uppercase'],
            styles['review-col-ellipsis']
          )}
        >
          {value}
        </span>
      );
    },
  },
  {
    field: 'members',
    text: 'REVIEWER(S)',
    draggable: false,
    hidden: true,
    renderer: ({ record }) => {
      return <ReviewerRenderer record={record.data} />;
    },
  },
  {
    field: 'deadline',
    text: 'DEADLINE',
    type: 'date',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    htmlEncode: false,
    renderer: ({ record }) => {
      return <DeadlineRenderer record={record.data} />;
    },
  },
  {
    field: 'progress',
    text: 'PROGRESS',
    draggable: false,
    hidden: true,
    htmlEncode: false,
    sortable: false,
    width: 108,
    minWidth: 108,
    renderer: ({ record }) => {
      return <ProgressRenderer record={record.data} />;
    },
  },
  {
    field: 'comment_count',
    text: 'COMMENTS',
    draggable: false,
    hidden: true,
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    htmlEncode: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'status_order_by',
    text: 'ROUND STATUS',
    width: 175,
    draggable: false,
    renderer: ({ record }) => {
      return <StatusRenderer record={record.data} />;
    },
  },
  {
    field: 'created_at',
    text: 'DATE CREATED',
    align: 'center',
    hidden: false,
    width: 150,
    draggable: false,
    renderer: ({ record }) => {
      return <DateCreatedRenderer record={record.data} />;
    },
  },
];

const DEFAULT_COLUMNS_INSIDE_PROJECT = [
  {
    field: 'source_img',
    text: '',
    width: 50,
    align: 'center',
    hideable: false,
    sortable: false,
    draggable: false,
    resizable: false,
    renderer: ({ record }) => {
      const { data } = record || {};
      return <SourceRenderer record={data} />;
    },
  },
  {
    field: 'source',
    text: 'REVIEW TITLE',
    width: 200,
    draggable: false,
    cellCls: styles['custom-col-review'],
    renderer: ({ record }) => {
      const { data } = record || {};
      return <ReviewRenderer record={data?.round_title} />;
    },
  },
  {
    field: 'round_number',
    text: 'ROUND',
    hidden: true,
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    align: 'center',
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'name',
    text: 'LATEST STAGE',
    cellCls: styles['custom-col-wrapper'],
    width: 150,
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'round_type_order_by',
    text: 'TYPE',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    renderer: ({ value }) => {
      return (
        <span
          className={clsx(
            styles['review-col-uppercase'],
            styles['review-col-ellipsis']
          )}
        >
          {value}
        </span>
      );
    },
  },
  {
    field: 'members',
    text: 'REVIEWER(S)',
    hidden: true,
    draggable: false,
    renderer: ({ record }) => {
      return <ReviewerRenderer record={record.data} />;
    },
  },
  {
    field: 'deadline',
    text: 'DEADLINE',
    type: 'date',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    htmlEncode: false,
    renderer: ({ record }) => {
      return <DeadlineRenderer record={record.data} />;
    },
  },
  {
    field: 'progress',
    text: 'PROGRESS',
    draggable: false,
    htmlEncode: false,
    sortable: false,
    width: 108,
    minWidth: 108,
    renderer: ({ record }) => {
      return <ProgressRenderer record={record.data} />;
    },
  },
  {
    field: 'comment_count',
    text: 'COMMENTS',
    draggable: false,
    hidden: true,
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    htmlEncode: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'status_order_by',
    text: 'ROUND STATUS',
    width: 175,
    draggable: false,
    renderer: ({ record }) => {
      return <StatusRenderer record={record.data} />;
    },
  },
  {
    field: 'created_at',
    text: 'DATE CREATED',
    align: 'center',
    hidden: false,
    width: 150,
    draggable: false,
    renderer: ({ record }) => {
      return <DateCreatedRenderer record={record.data} />;
    },
  },
];

const DEFAULT_COLUMNS_TASK_FORM = [
  {
    field: 'source_img',
    text: '',
    width: 50,
    align: 'center',
    hideable: false,
    sortable: false,
    draggable: false,
    resizable: false,
    renderer: ({ record }) => {
      const { data } = record || {};
      return <SourceRenderer record={data} />;
    },
  },
  {
    field: 'source',
    text: 'REVIEW TITLE',
    width: 200,
    draggable: false,
    cellCls: styles['custom-col-review'],
    renderer: ({ record }) => {
      const { data } = record || {};
      return <ReviewRenderer record={data?.round_title} />;
    },
  },
  {
    field: 'round_number',
    text: 'ROUND',
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    align: 'center',
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'name',
    text: 'LATEST STAGE',
    cellCls: styles['custom-col-wrapper'],
    width: 150,
    draggable: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'round_type_order_by',
    text: 'TYPE',
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    renderer: ({ value }) => {
      return (
        <span
          className={clsx(
            styles['review-col-uppercase'],
            styles['review-col-ellipsis']
          )}
        >
          {value}
        </span>
      );
    },
  },
  {
    field: 'members',
    text: 'REVIEWER(S)',
    draggable: false,
    renderer: ({ record }) => {
      return <ReviewerRenderer record={record.data} />;
    },
  },
  {
    field: 'deadline',
    text: 'DEADLINE',
    type: 'date',
    hidden: true,
    cellCls: styles['custom-col-wrapper'],
    draggable: false,
    htmlEncode: false,
    renderer: ({ record }) => {
      return <DeadlineRenderer record={record.data} />;
    },
  },
  {
    field: 'progress',
    text: 'PROGRESS',
    hidden: true,
    draggable: false,
    htmlEncode: false,
    sortable: false,
    width: 108,
    minWidth: 108,
    renderer: ({ record }) => {
      return <ProgressRenderer record={record.data} />;
    },
  },
  {
    field: 'comment_count',
    text: 'COMMENTS',
    draggable: false,
    hidden: true,
    cellCls: clsx(styles['custom-col-wrapper'], styles['custom-col-align']),
    htmlEncode: false,
    renderer: ({ value }) => {
      return <span>{value}</span>;
    },
  },
  {
    field: 'status_order_by',
    text: 'ROUND STATUS',
    width: 175,
    draggable: false,
    renderer: ({ record }) => {
      return <StatusRenderer record={record.data} />;
    },
  },
  {
    field: 'created_at',
    text: 'DATE CREATED',
    align: 'center',
    hidden: false,
    width: 150,
    draggable: false,
    renderer: ({ record }) => {
      return <DateCreatedRenderer record={record.data} />;
    },
  },
];

const PARAMS_DEFAULT = {
  filter: {
    job_id: REQUEST.jobId > 0 && !REQUEST.taskId ? REQUEST.jobId : undefined,
  },
};

const INSIDE_PROJECT = !!REQUEST.jobId;
const defaultTime = 15000;

const DocumentReviewGrid = (props) => {
  const {
    gridProps,
    paramFilter,
    leftNavParam,
    filterFlyoutParam,
    searchValue,
    limit = 10,
    isTaskForm,
    ticketId = 0,
    rejectFilterJobId = false,
  } = props;

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [gridAction, setGridAction] = useState(GRID_ACTIONS.READ);

  const params = useMemo(
    () => ({
      filter: {
        ...PARAMS_DEFAULT.filter,
        ...(rejectFilterJobId && { job_id: undefined }),
      },
    }),
    [rejectFilterJobId]
  );

  const queryParamsString = qs.stringify(params, { encode: false });

  const urlEndpoint = `${URLS.app}api/round-stages?${queryParamsString}`;

  const viewBtnColumn = {
    field: 'view_btn',
    region: 'right',
    text: '',
    align: 'center',
    hideable: false,
    sortable: false,
    draggable: false,
    resizable: false,
    minWidth: 185,
    cellCls: styles['view-btn'],
    renderer: ({ record, grid }) => {
      const currentRow = grid?.store?.records.filter((row) => {
        return row.data.round_id === record.round_id;
      });
      const currentStatus = currentRow.length
        ? currentRow[0].data.round_status
        : record.data.round_status;

      const disableViewProof =
        UNAVAILABLE_REVIEW_STATUSES.includes(currentStatus);
      return (
        <ViewBtnRenderer
          record={record.data}
          ajaxReviewGridStore={grid?.store}
          disableViewProofBtn={disableViewProof}
          ticketId={ticketId}
        />
      );
    },
  };

  const gridRef = useRef();
  const intervalRef = useRef(0);
  const [filterCondition, setFilterCondition] = useState(
    paramFilter || undefined
  );
  const [isPolling, setIsPolling] = useState(false);
  const [hasReview, setHasReview] = useState(true);
  const reloadGridFlag = useRef(false);

  // apply for reviews tab and inbox reviews tab
  const isEmptyStateReviews = useMemo(
    () => CONTENT !== 'job-tasklist' && !hasReview,
    [hasReview]
  );
  let stageData;

  /**
   *
   * Handle Deepslink
   */
  const renderReviewDetail = (assetId = 0, roundId) => {
    const reviewGridAssetDetailContainer =
      document.querySelector('#review-detail');
    ReactDOM.render(
      <Suspense fallback={<div>Loading...</div>}>
        <PulseAssetDetail
          initialState={{
            assetDetail: {
              asset: {
                assetId,
              },
              isZiflow: true,
              isOpenDrawer: true,
              isOpenHistory: false,
              isAdvancedPanelCreated: false,
              isOpenAdvanceDetails: false,
              isOpenWorkFlowTabs: false,
              isOpenAssetDetailsTabs: false,
              isConfirmationModalVisible: false,
            },
            assetComments: {
              comments: [],
              versionComments: [],
              ziflowActivities: [],
            },
            assetVersions: {
              comments: [],
            },
            ticket: {
              isUpdatingTicketProperty: [],
              isUpdatingTicketField: [],
            },
            ticketHistory: {},
            emailHistory: {
              data: [],
            },
            review: {
              reviewRound: null,
              tasks: [],
              isLoadingReviewRound: false,
              setOpenReviewModal,
            },
          }}
          ticketId={ticketId}
          roundId={roundId}
          assetId={assetId}
        />
      </Suspense>,
      reviewGridAssetDetailContainer
    );
  };
  useEffect(() => {
    const { hash } = window.location;

    if (hash && CONTENT === 'passport-reviews') {
      handleRoundDeepLink(hash);
    }
  }, [setOpenReviewModal]);

  //
  const handleRoundDeepLink = async (hash) => {
    const [reviewIdDeepLink, roundNumberDeepLink] =
      useReviewIdByDeepLinks(hash);
    if (reviewIdDeepLink > 0) {
      utilities.blockPage();
      await v2Endpoint
        .get(`${REVIEW_URL}/${reviewIdDeepLink}`)
        .then(({ data }) => {
          const dataRes = parseJsonApi(data);
          const { rounds } = camelizeKeys(dataRes);
          if (Array.isArray(rounds)) {
            const latestRoundId = rounds[rounds.length - 1].id;
            const latestRoundNumber = rounds[rounds.length - 1].roundNumber;
            if (roundNumberDeepLink <= 0) {
              renderReviewDetail(0, rounds[rounds.length - 1].id);
              utilities.unblockPage();
              return;
            }
            rounds.forEach((round) => {
              if (round.roundNumber === roundNumberDeepLink) {
                if (latestRoundNumber > roundNumberDeepLink) {
                  window.history.pushState(
                    {
                      roundId: round.id,
                      lastRoundId: latestRoundId,
                      redirectRound: true,
                    },
                    ''
                  );
                } else {
                  window.history.pushState(
                    {
                      roundId: round.id,
                    },
                    ''
                  );
                }
                renderReviewDetail(0, round.id);
                utilities.unblockPage();
              }
            });
          }
        });
    }
  };

  /**
   * end
   * */

  const handleReloadGrid = (e) => {
    const { gridInstance } = gridRef?.current;
    const { store: gridAjaxStore } = gridInstance;

    if (e.detail && gridInstance) {
      if (hasReview) {
        gridAjaxStore.loadPage(1);
        return;
      }

      reloadGridFlag.current = true;
      setHasReview(true);
    }
  };

  useEffect(() => {
    const { gridInstance } = gridRef?.current;
    if (reloadGridFlag.current && hasReview && gridInstance) {
      const { store: gridAjaxStore } = gridInstance;
      gridAjaxStore.loadPage(1);
    }
    // create a event to re-load grid
    document.addEventListener('reload-review-grid', handleReloadGrid);
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener(
        'reload-review-grid',
        handleReloadGrid,
        false
      );
    };
  }, [hasReview]);

  useEffect(() => {
    if (paramFilter) {
      setFilterCondition(paramFilter);
      return;
    }
    if (filterFlyoutParam)
      setFilterCondition(leftNavParam ?? filterFlyoutParam);
  }, [paramFilter, leftNavParam, filterFlyoutParam]);

  useEffect(() => {
    const { gridInstance } = gridRef?.current;
    if (!gridInstance) {
      return;
    }
    const { store: gridAjaxStore } = gridInstance;

    if (searchValue || filterCondition || paramFilter) {
      const searchParam = {};
      const searchKey = 'filter[qsearch]';
      if (searchValue) {
        searchParam[searchKey] = searchValue;
      }
      const includedParam = {
        ...DEFAULT_AJAX_STORE_PARAMS,
        ...filterCondition,
        ...searchParam,
      };
      const hasFilter =
        Object.keys({ ...filterCondition, ...searchParam }).length > 0;
      const getActionGrid = hasFilter ? GRID_ACTIONS.FILTER : GRID_ACTIONS.READ;
      gridAjaxStore.setConfig({ params: includedParam });
      gridAjaxStore.loadPage(1, includedParam);

      if (gridAction === GRID_ACTIONS.FILTER && !hasFilter && !hasReview) {
        setHasReview(true);
        return;
      }
      if (setGridAction) {
        setGridAction(getActionGrid);
      }
    }
  }, [filterCondition, searchValue, hasReview]);

  useEffect(() => {
    if (!gridRef.current) {
      return;
    }
    const { gridInstance } = gridRef.current;

    if (Array.isArray(gridRef.current.records) && handleExistReviews) {
      if (gridRef.current.records.length <= 0) {
        handleExistReviews(false);
      }
    }

    /**
     * Save the columns that should be display
     */
    gridInstance.columns.on('change', () => {
      const columns = gridInstance.columns.records.reduce(
        (results, column) => ({
          ...results,
          [column.field]: {
            width: column.width,
            hidden: column.hidden,
            order: column.parentIndex,
          },
        }),
        {}
      );

      if (INSIDE_PROJECT) {
        if (isTaskForm) {
          localStorage.setItem(
            COLUMNS_CONFIGS_KEY_TASK_FORM,
            JSON.stringify(columns)
          );
        } else {
          localStorage.setItem(
            COLUMNS_CONFIGS_KEY_INSIDE_PROJECT,
            JSON.stringify(columns)
          );
        }
      } else {
        localStorage.setItem(
          COLUMNS_CONFIGS_KEY_NON_PROJECT,
          JSON.stringify(columns)
        );
      }
    });
  }, [gridRef?.current]);

  intervalRef.current = useInterval(() => {
    if (RUN_WEBSOCKETS) {
      const WEBSOCKET_CONNECTION =
        !!window?.Echo?.connector?.pusher?.connection?.connection;

      if (WEBSOCKET_CONNECTION) {
        setIsPolling(false);
      } else {
        setIsPolling(true);
      }
    }
  }, defaultTime);

  // Connect to websocket

  useEffect(() => {
    if (USER && RUN_WEBSOCKETS && !isPolling) {
      window.Echo.channel('ziflow-review-grid').listen(
        '.ziflow-event-grid',
        (response) => {
          // Need to show on console to check websocket works

          // Removing update_at field to avoid rerendering
          delete response.data.updated_at;

          if (!isEqual(response.data, stageData) && gridRef.current) {
            const gridInstance = gridRef.current.instance;
            const { store: gridAjaxStore } = gridInstance;
            const dataInCurrentViewGrid = gridAjaxStore.records;

            const updatedRow = dataInCurrentViewGrid.find((row) => {
              if (row.data.related_rounds[0].id === response.data.round_id) {
                return row;
              }
              return undefined;
            });

            if (
              updatedRow &&
              response.data.status !== STAGE_STATUS.notStarted // TODO: we just update started stage
            ) {
              const updatedData = {
                name: response.data.name,
                reviewers: response.data.reviewers,
                progress: response.data.progress,
                source: response.data.round_sources[0],
                round_number: response.data.round_name,
                round_id: response.data.round_id,
                deadline: response.data.deadline,
                round_type_order_by: response.data.round_type,
                status_order_by: response.data.status,
                comment_count: response.data.comment_count,
                job_id: response.data.job_id,
                job_title: response.data.job_title,
                brand_title: response.data.job_brand,
                job_number: response.data.job_number,
                created_at: response.data.created_at,
                last_round_number: response.data.last_round_number,
                manage: response.data.manage,
                round_title: response.data.round_title,
                round_status: response.data.round_status,
                round_stage_id: response.data.round_stage_id,
                round_thumbnail_link: response.data.round_thumbnail_link,
                review_id: response.data.review_id,
              };

              updatedRow.set(updatedData);
            }
          }
          stageData = response.data;
        }
      );
    }

    let id;
    if (isPolling) {
      id = useInterval(() => {
        const { gridInstance } = gridRef?.current;
        const { store: gridAjaxStore } = gridInstance;
        const { _widgetMap } = gridInstance;
        // eslint-disable-next-line no-underscore-dangle
        const currentPage = _widgetMap.pageNumber._value;
        gridAjaxStore.loadPage(currentPage);
      }, defaultTime);
    }
    return () => {
      clearInterval(id);
      if (window.Echo) {
        window.Echo.leaveChannel('ziflow-review-grid');
      }
    };
  }, [isPolling]);

  const columnsConfig = () => {
    let defaultColumns = COLUMNS_CONFIGS_KEY_NON_PROJECT;

    let columns;

    if (INSIDE_PROJECT) {
      if (isTaskForm) {
        columns = DEFAULT_COLUMNS_TASK_FORM;
        defaultColumns = COLUMNS_CONFIGS_KEY_TASK_FORM;
      } else {
        columns = DEFAULT_COLUMNS_INSIDE_PROJECT;
        defaultColumns = COLUMNS_CONFIGS_KEY_INSIDE_PROJECT;
      }
    } else {
      columns = DEFAULT_COLUMNS_NON_PROJECT;
    }

    const columnsConfigsStorage = localStorage.getItem(defaultColumns);

    if (columnsConfigsStorage) {
      columns = columns.map((column) => {
        const columnConfigs = JSON.parse(columnsConfigsStorage)[column.field];
        return {
          ...column,
          ...columnConfigs,
        };
      });

      columns.sort();
    }
    return [...columns, viewBtnColumn];
  };

  const AJAX_STORE_CONFIG = {
    readUrl: urlEndpoint,
    autoLoad: false,
    params: DEFAULT_AJAX_STORE_PARAMS,
    pageSize: limit,
    pageParamName: 'page[number]',
    pageSizeParamName: 'page[per_page]',
    responseTotalProperty: 'page.total',
    listeners: {
      afterRequest: () => {
        if (intervalRef.current && isPolling) {
          clearInterval(intervalRef.current);
        }
      },
      refresh: ({ source: ajaxStore, action }) => {
        // except flag reloadGridFlag
        if (!reloadGridFlag.current) {
          // handle hasReview
          if (action === 'pageLoad' && Array.isArray(ajaxStore.records)) {
            const hasRecords = ajaxStore.records.length > 0;

            if (hasRecords !== hasReview) {
              setHasReview(hasRecords);
            }
          }
        }
        reloadGridFlag.current = false;
      },
      sort: ({ source: ajaxStore, sorters }) => {
        // Handle sorting
        if (ajaxStore) {
          const { params: currentParams } = ajaxStore;
          const { ascending, field } = sorters[0];
          const params = {
            ...currentParams,
            sort: ascending
              ? FIELDS_TO_COLUMNS[field]
              : `-${FIELDS_TO_COLUMNS[field]}`,
          };
          ajaxStore.setConfig({ params });
          ajaxStore.loadPage(1, params);
        }
      },
    },
  };

  return (
    <>
      <ReviewModalContext
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
        gridAction={gridAction}
        setGridAction={setGridAction}
      >
        {useMemo(
          () => (
            <>
              <div
                className={styles['pulse-bryntum-grid-review']}
                data-testid="pulse-bryntum-grid-review"
                key={
                  JSON.stringify(filterCondition) +
                  JSON.stringify(searchValue) +
                  JSON.stringify(hasReview)
                }
                data-empty={isEmptyStateReviews}
              >
                <PulseBryntumGrid
                  ref={gridRef}
                  designVariant="v3"
                  gridProps={{
                    hidden: isEmptyStateReviews,
                    columns: columnsConfig(),
                    sortFeature: true,
                    rowHeight: 48,
                    cellMenuFeature: false,
                    cellCls: styles['custom-col-wrapper'],
                    headerMenuFeature: {
                      items: {
                        hideColumn: false,
                        multiSort: false,
                      },
                    },
                    ...gridProps,
                  }}
                  ajaxStoreConfig={AJAX_STORE_CONFIG}
                  paginate={true}
                />
              </div>
              {isEmptyStateReviews && <EmptyReview />}
            </>
          ),
          [isEmptyStateReviews]
        )}
      </ReviewModalContext>
    </>
  );
};

DocumentReviewGrid.propTypes = {
  ajaxStore: PropTypes.object,
  gridProps: PropTypes.object,
  paramFilter: PropTypes.object,
  leftNavParam: PropTypes.object,
  filterFlyoutParam: PropTypes.object,
  searchValue: PropTypes.string,
  limit: PropTypes.number,
  isTaskForm: PropTypes.bool,
  ticketId: PropTypes.number,
  rejectFilterJobId: PropTypes.bool,
};

export default React.memo(DocumentReviewGrid);
